<template>
  <div v-if="overview">
    <p class="subtitle is-6 has-text-dark">Vehicle overview</p>
    <p class="title is-4 is-size-5-mobile has-text-dark">
      {{ overview.description }}
    </p>
    <claims v-if="permissions.claimsModule" />
    <trade v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanionOverview',
  components: {
    claims: () => import('./valuation/CompanionValuationsClaims'),
    trade: () => import('./valuation/CompanionValuationsTrade')
  },
  mounted() {
    this.$mxp.track('companion_page_load')
  },
  computed: {
    ...mapGetters('companion', ['overview']),
    ...mapGetters('auth', ['permissions'])
  }
}
</script>
